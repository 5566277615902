<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="0" class="pa-0 ma-0" outlined :loading="loading">
      <v-data-table
          :loading="loading"
          :headers="headerToRender"
          :items="dataToRender"
      >
        <template v-slot:body="props">
          <tr v-for="(item,key) in props.items" :key="key" style="border-bottom:1px solid rgba(0,0,0,0.15);">
            <td v-for="(header,index) in headerToRender" :key=index>
              <v-row justify="center" align="center" v-if="index>0">
                <v-checkbox :loading="loading" :value="1" v-model="item[header.value].status"
                            @change="update(item[header.value],item['id'])"/>
              </v-row>
              <v-row v-else justify="center" align="center">
                <span>{{ item[header.value] }}</span>
              </v-row>
            </td>
          </tr>
        </template>

        <!--<template v-slot:body="props" >
            <td v-for="(header,index) in datatableHeaders" :key="index">
                <div v-if="header.value!=='id'">
                <span v-for="(item,i) in props.items" :key="i">
                    <v-checkbox v-model="item[header.value].status" value="1" :disabled="loading"
                                @change="update([(item[header.value].status)?1:0,item[header.value].id,item['id']])"></v-checkbox>

                </span>
                </div>

            </td>
        </template>-->

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import {Events} from "../../../../plugins/events";

export default {
  name: "GlobalePaymentPermissionComponent",
  watch: {
    dataTableHeaders() {
      this.headerToRender = this.filteredDatatableHeaders;
    },
    dataToShow() {
      this.dataToRender = this.filteredDatatableEntries;
    }
  },
  computed: {
    filteredDatatableHeaders() {
      let headers = [];
      this.dataTableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }
        headers.push(header);
      });
      return headers;
    },
    filteredDatatableEntries() {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};
        this.dataTableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];
          }
        });

        dataToRender.push(row);
      });


      return dataToRender;
    },
  },
  data() {
    return {
      loading: false,
      dataToShow: [],
      dataTableHeaders: [],
      dataToRender: [],
      headerToRender: [],
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.axios.post('get/settings/activePayments/')
          .then(res => {
            let cashierID = null;
            this.dataTableHeaders = [];
            this.dataToShow = [];

            let _payments = res.data.payments;
            let _data = res.data.data;
            let headers = [], dataTable = [];

            headers.push({
              text: 'POS ID',
              align: 'center',
              value: 'id',
              width: 40
            },)

            for (const prop in _payments) {
              headers.push({
                text: this.$t(_payments[prop].paymentName),
                align: 'center',
                value: prop,
                width: 100
              })
            }

            this.dataTableHeaders = headers;

            let row = [];
            for (const prop in _data) {
              row.push(prop);
              for (const attr in _data[prop].paymentInfo) {
                row.push({
                  id: _data[prop].paymentInfo[attr].paymentID,
                  status: _data[prop].paymentInfo[attr].paymentActive
                })
              }
              dataTable.push(row);
              row = [];
            }

            this.dataToShow = dataTable;

            this.loading = false;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_error'),
          color: "error"
        });
        this.loading = false;
      })
    },
    update(data, id) {

      this.loading = true
      this.axios.post('update/settings/activePayments/', {
        paymentActive: data.status,
        paymentID: data.id,
        cashierID: id,
      }).then(res => {
        this.getDataFromApi();

      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_error'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.getDataFromApi()
  }
}
</script>

<style scoped>

</style>