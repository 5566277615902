<template>
  <v-container class="pa-0" fluid>
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0 text-right" cols="12">
        <v-btn @click="dialog=true" class="bg-success text-light elevation-0">
          {{ this.$t('erp.lang_newTemplate') }}
        </v-btn>
      </v-col>


      <v-col cols="12">
        <v-dialog scrollable v-model="dialog" width="600px">
          <v-card>
            <v-card-title :class="[$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">{{ this.formTitle }}</v-card-title>
            <v-card-text class="pa-0 ma-0">
              <v-row class="mx-0">
                <v-col class="pa-0 ma-0" cols="12">
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                        class="pa-0 ma-0"
                    >
                      <v-expansion-panel-header>
                        <span>{{ $t('delivery.lang_individual_invoice_variables') }}</span>

                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-0">
                        <v-row>
                          <v-col cols="12">
                            <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                          :placeholder="$t('datatables.lang_search')" v-model="search"/>
                          </v-col>
                          <v-col cols="12">
                            <v-row class="pa-0 ma-0">
                              <v-col :key="i" class="pt-0 pb-0" cols="12" md="6" sm="12"
                                     v-for="(elt , i) in this.variations.filter((v) => v.toLocaleLowerCase().includes(search.toLocaleLowerCase()))">
                                <v-text-field :id="'input_' + i" :value="elt"
                                              :background-color="$vuetify.theme.dark? 'dark-bg' : 'grey lighten-3'"
                                              dense outlined
                                              readonly>
                                  <template v-slot:append>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="copyText('input_' + i , elt)" @mouseout="out" icon v-bind="attrs"
                                               v-on="on">
                                          <v-icon>content_copy</v-icon>
                                        </v-btn>
                                      </template>
                                      {{ toolpitMsg }}
                                    </v-tooltip>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <v-col class="pa-0 ma-0" cols="12">
                  <v-form lazy-validation ref="form">
                    <v-row class="pa-0 ma-0">

                      <v-col class="pb-0 mb-0" cols="12">
                        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="this.$t('erp.lang_TemplateName')"
                                      :rules="[ v => !!v]"
                                      @focus="showTouchKeyboard"
                                      dense outlined v-model="editedItem.name"/>
                      </v-col>
                      <v-col class="pt-0 mt-0" cols="12">
                        <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions" :rules="[v => !!v]"
                                      @focus="showTouchKeyboard"
                                      output="html" v-model="editedItem.text"></quill-editor>
                      </v-col>

                      <v-col cols="12">
                        <b-form-checkbox :value="1" class="ml-2" switch v-model="editedItem.standard">
                          {{$t('generic.lang_standard')}}?
                        </b-form-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="card-footer text-right pt-1 pb-1">
              <v-spacer/>
              <v-btn @click="close" class="bg-danger text-light mx-0">
                {{ this.$t('generic.lang_cancel') }}
              </v-btn>
              <v-btn v-if="!edit" :disabled="this.loading" :loading="this.loading" @click="saveTamplate"
                     class="bg-primary text-light mx-1">
                {{ this.$t('generic.lang_save') }}
              </v-btn>
              <v-btn v-else :disabled="this.loading" :loading="this.loading" @click="updateTemplate"
                     class="bg-primary text-light mx-1">
                {{ this.$t('generic.lang_edit') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


      <v-col class="mt-0 pt-0" cols="12">
        <v-row>
          <v-col class="pa-0 ma-0" cols="12">
            <Datatable :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.SYSTEMSETTINGS.INVOICESETTINGS.TEXTTEMPLATES"
                       :datatable-headers="this.headers"
                       :elevation="0"
                       :permissionDelete="true"
                       :permissionEdit="true"
                       @deleteEntries="entriesDelete"
                       @deleteEntry="entryDelete"
                       @editEntry="entryEdit"
                       no-excel-export
                       ref="texttemplates"
                       :data="{
                          bSearchable_4:true,
                          sSearch_4:0,
                       }"
                       show-delete-buttons
                       show-edit-buttons
                       v-show="true">

              <template v-slot:item.standard="{item}">
                <v-checkbox true-value="1" false-value="0" v-model="item.standard" readonly disabled />
              </template>
            </Datatable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

import mixin from "@/mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";

export default {
  name: "TextTemplateComponent",
  components: {Datatable, quillEditor},
  mixins: [mixin],
  data() {
    return {
      editedItem: {
        name: "",
        text: "",
        isStandard: 0
      },
      default: {
        name: "",
        text: "",
        isStandard: 0
      },
      quillOptions: {
        modules: {

          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },
      edit: false,
      loading: false,
      ENDPOINTS,
      search: "",
      dialog: false,
      toolpitMsg: this.$t('generic.lang_CopyToClipboard'),
    }
  },

  methods: {
    saveTamplate() {
      if (!this.$refs.form.validate()) return;
      if (this.editedItem.text === "") {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_pleaseSetYourTemplateText'),
          color: "error"
        });
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.TEXTTEMPLATES.CREATE, {
        template: {
          name: this.editedItem.name,
          text: this.editedItem.text,
          isStandard: this.editedItem.isStandard
        },
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });

          this.$refs.texttemplates.getDataFromApi();
          this.$refs.texttemplates.resetSelectedRows();
          this.close();
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    updateTemplate(){
      if (!this.$refs.form.validate()) return;
      if (this.editedItem.text === "") {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_pleaseSetYourTemplateText'),
          color: "error"
        });
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.TEXTTEMPLATES.UPDATE, {
        template: {
          uuid: this.editedItem.id,
          name: this.editedItem.name,
          text: this.editedItem.text,
          isStandard: this.editedItem.isStandard
        },
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });

          this.$refs.texttemplates.getDataFromApi();
          this.$refs.texttemplates.resetSelectedRows();
          this.close();
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    out() {
      this.toolpitMsg = this.$t('generic.lang_CopyToClipboard')
    },
    copyText(id, elt) {
      //console.log(id)
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.toolpitMsg = this.$t('generic.lang_copied') + ": " + elt;
      this.editedItem.text += " " + elt;
    },
    deleteData(ids) {
      this.$swal({
        title: this.$t('erp.lang_deletePriceTmplText'),
        text: this.$t('erp.lang_deletePriceTmplText'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.TEXTTEMPLATES.DELETE, {
            templateUUIDs: ids
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_itemSuccessfulDeleted'),
                color: "success"
              });
              this.$refs.texttemplates.getDataFromApi();
              this.$refs.texttemplates.resetSelectedRows();
            } else if (res.data.status === 'FAILED') {
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error"
            });
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    entriesDelete(ids) {
      this.deleteData(ids);
    },
    entryDelete(entry) {
      this.deleteData([entry.id]);
    },
    entryEdit(entry) {
      this.editedItem = Object.assign({}, entry);
      this.edit = true;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.default);
      this.edit=false,
      this.$refs.form.reset();
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  computed: {
    formTitle: function () {
      return this.edit ? this.$t('erp.lang_editTemplate') : this.$t('erp.lang_newTemplate')
    },
    headers: function () {
      return [
        {
          text: 'id',
          value: 'id',
          hide: true
        },
        {
          text: this.$t('erp.lang_TemplateName'),
          value: 'name',
          sortable: false
        },
        {
          text: "description",
          value: 'text',
          sortable: false,
          hide: true
        },
        {
          text: this.$t('generic.lang_standard'),
          value: 'standard',
        },
        {
          text: this.$t('generic.lang_standard'),
          value: 'isStandard',
          hide: true
        }
      ]
    },
    variations() {
      return [
        this.$t('customers.lang_cust_prename') + "  - {{Prename}}",
        this.$t('customers.lang_cust_name') + "  - {{Lastname}}",
        this.$t('settings.lang_companyName') + "  - {{Company}}",
        this.$t('generic.lang_address') + "  - {{Address}}",
        this.$t('customers.lang_cust_zip') + "  - {{Zip}}",
        this.$t('settings.lang_city') + "  - {{City}}",
        this.$t('generic.lang_country') + "  - {{Country}}",
        this.$t('settings.lang_accountOwner') + "  - {{KontoInhaber}}",
        "IBAN  - {{IBAN}}",
        "BIC  - {{BIC}}",
        this.$t('settings.lang_bankName') + "  - {{Bankname}}",
        this.$t('accounting.lang_MandateReference') + "  - {{Manadatsreferen}}",
        this.$t('accounting.lang_CreditorID') + "  - {{GlaeubigerID}}",
        this.$t('settings.lang_contactFirstName') + "  - {{contactPrename}}",
        this.$t('settings.lang_contactLastName') + "  - {{contactLastname}}",
        this.$t('settings.lang_contactAdress') + "  - {{contactAdress}}",
        this.$t('settings.lang_contactZipCode') + "  - {{contactZip}}",
        this.$t('settings.lang_contactCity') + "  - {{contactCity}}",
        this.$t('settings.lang_contactCountry') + "  - {{contactCountry}}",
        this.$t('settings.lang_deliveryPrenam') + "  - {{deliveryPrenam}}",
        this.$t('settings.lang_deliveryLastname') + "  - {{deliveryLastname}}",
        this.$t('settings.lang_deliveryAddress') + "  - {{deliveryAddress}}",
        this.$t('settings.lang_deliveryZip') + "  - {{deliveryZip}}",
        this.$t('settings.lang_deliveryCity') + "  - {{deliveryCity}}",
        this.$t('settings.lang_deliveryCountry') + "  - {{deliveryCountry}}",
        this.$t('settings.lang_billingPrename') + "  - {{billingPrename}}",
        this.$t('settings.lang_billingLastname') + "  - {{billingLastname}}",
        this.$t('settings.lang_billingAdress') + "  - {{billingAdress}}",
        this.$t('settings.lang_billingZip') + "  - {{billingZip}}",
        this.$t('settings.lang_billingZip') + "  - {{billingZip}}",
        this.$t('settings.lang_billingCity') + "  - {{billingCity}}",
        this.$t('settings.lang_billingCountry') + "  - {{billingCountry}}",
        this.$t('settings.lang_billingPaymentDeadline') + "  - {{billingPaymentDeadline}}",
        this.$t('settings.lang_billingDatePaymentDeadline') + "  - {{billingDatePaymentDeadline}}"
      ]
    },
  }
}
</script>

<style scoped>
v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
  border: none !important;
}
</style>
