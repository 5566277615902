<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayInvoiceDiscounts">
          {{ $t('settings.lang_receiptDisplayDiscount') }}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayInvoicePriceChanges">
          {{ $t('settings.lang_receiptBonWizzardPriceChanges') }}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayInvoiceVoidBarcode">
          {{ $t('erp.lang_receiptDisplayRetoureBarcode') }}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayInvoiceNetItemPrices">
          {{$t('accounting.lang_receiptDisplayNetPrice')}}
        </b-form-checkbox>
      </v-col>


      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayInvoiceTableNo">
          {{$t('settings.lang_printTableNoOnReceipt')}}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayDebitorNoInsteadCustNumber">
          {{$t('customers.lang_receiptDisplayDebitorInsteadOfCustomerID')}}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayInvoiceSenderAddress">
          {{$t('settings.lang_displaySenderAddress')}}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayInvoiceLogo">
          {{$t('settings.lang_displayInvoiceLogo')}}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayNetPriceForItem">
          {{$t('settings.lang_displayNetPriceForItem')}}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.displayItemDescription">
          {{$t('settings.lang_displayItemDescription')}}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.appendEanNoToItemDesc">
          {{$t('settings.lang_lang_append_ean_no_to_item_desc')}}
        </b-form-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <b-form-checkbox :value="1" v-model="form.appendInternalItemNoToDesc">
          {{$t('settings.lang_append_internal_item_no_to_desc')}}
        </b-form-checkbox>
      </v-col>

      <!--
            <v-col cols="12" md="6">
              <strong>Tax display layout</strong>
              <br>
              <v-btn-toggle color="primary">
                <v-btn class="ma-0">
                  <v-icon>mdi-format-align-left</v-icon>
                </v-btn>

                <v-btn class="ma-0">
                  <v-icon>mdi-format-align-right</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12" md="6">
              <strong>Tax layout box position</strong>
              <br>
              <v-btn-toggle color="primary">
                <v-btn class="ma-0">
                  <v-icon>mdi-format-align-left</v-icon>
                </v-btn>

                <v-btn class="ma-0">
                  <v-icon>mdi-format-align-right</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
      -->
      <v-col cols="12">
        <v-btn :disabled="this.loading" :loading="this.loading" @click="saveSettings" block
               class="mx-auto bg-primary text-light">{{
            this.$t('generic.lang_save')
          }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "SettingsComponent",
  data: () => {
    return {
      loading: false,
      form: {
        displayInvoiceTableNo: 0,
        displayInvoiceVoidBarcode: 0,
        displayInvoicePriceChanges: 0,
        displayInvoiceDiscounts: 0,
        displayInvoiceNetItemPrices: 0,
        displayDebitorNoInsteadCustNumber: 0,
        displayInvoiceLogo: 0,
        displayInvoiceSenderAddress: 0,
        displayItemDescription: 0,
        displayNetPriceForItem: 0,
        appendEanNoToItemDesc: 0,
        appendInternalItemNoToDesc: 0,
      },
      layoutPositions: [
        {
          text: "Left",
          value: "left"
        },
        {
          text: "Right",
          value: "right"
        }
      ]
    }
  },
  mounted() {
    this.loadHeader();
  },
  methods: {
    loadHeader() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.GET, {
        updatePart: "invoiceSettings"
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          this.form = res.data.settings;
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;
      this.form.updatePart = "invoiceSettings";
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.UPDATE, this.form).then((res) => {

        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>