<template>
    <div class="main-card mb-3 card pa-0 ma-0" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">

        <div>
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

                <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                    Logos
                </div>
            </div>
            <v-divider class="pa-0 ma-0"/>

            <v-row v-if="loading" class="pa-5">
                <v-col cols="12" class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    <p>{{$t('datatables.lang_Processing')}}...</p>
                </v-col>
            </v-row>

            <v-row v-if="!loading" class="pa-5">
                <v-col cols="12" sm="6">
                    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card shadow-sm">
                        <div class="pa-0 ma-0 dropdown-menu-header">

                            <div :class="[this.$vuetify.theme.dark? 'text-white' : 'text-dark']"  class="card-header bg-muted">
                               {{$t('accounting.lang_receiptlogo')}}
                            </div>

                        </div>
                        <div class="card-body">
                            <image-cropper ref="ReceiptLogo"
                                           v-model="logos.ReceiptLogo" @update="updateImage('ReceiptLogo')"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                            <v-btn :disabled="loaders.ReceiptLogo" :loading="loaders.ReceiptLogo"
                                   @click="upload('ReceiptLogo')" block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0"
                                   large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{this.$t('generic.lang_hochladen')}}
                            </v-btn>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" sm="6">
                    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card shadow-sm">
                        <div class="pa-0 ma-0 dropdown-menu-header">

                            <div class="card-header bg-success text-light">
                              {{$t('accounting.lang_receipt_logo')}}
                            </div>

                        </div>
                        <div class="card-body">
                            <image-cropper ref="invoiceLogo" v-model="logos.invoiceLogo" @update="updateImage('invoiceLogo')"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                            <v-btn :disabled="loaders.invoiceLogo" :loading="loaders.invoiceLogo"
                                   @click="upload('invoiceLogo')" block
                                   class="btn bg-success text-light mx-auto mt-0 mb-0" large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{this.$t('generic.lang_hochladen')}}
                            </v-btn>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" sm="6">
                    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"  class="main-card mb-3 card shadow-sm">
                        <div class="pa-0 ma-0 dropdown-menu-header">

                            <div class="card-header bg-info text-light">
                              {{this.$t('accounting.lang_reportLogo')}}
                            </div>

                        </div>
                        <div class="card-body">
                            <image-cropper ref="ReportLogo" v-model="logos.ReportLogo" @update="updateImage('ReportLogo')"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                            <v-btn :disabled="loaders.ReportLogo" :loading="loaders.ReportLogo"
                                   @click="upload('ReportLogo')" block class="btn bg-info text-light mx-auto mt-0 mb-0"
                                   large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{this.$t('generic.lang_hochladen')}}
                            </v-btn>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" sm="6">
                    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"  class="main-card mb-3 card shadow-sm">
                        <div class="pa-0 ma-0 dropdown-menu-header">

                            <div class="card-header bg-primary text-light">
                              {{this.$t('accounting.lang_startPageLogo')}}
                            </div>

                        </div>
                        <div class="card-body">
                            <image-cropper ref="StartPageLogo" v-model="logos.StartPageLogo" @update="updateImage('StartPageLogo')"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                            <v-btn :disabled="loaders.StartPageLogo"
                                   :loading="loaders.StartPageLogo" @click="upload('StartPageLogo')" block
                                   class="btn bg-primary text-light mx-auto mt-0 mb-0" large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{this.$t('generic.lang_hochladen')}}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidth"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
    </div>


</template>


<script>
import {ENDPOINTS} from '../../../config'
import mixin from "../../../mixins/KeyboardMixIns";
import ImageCropper from "../../common/imagecropper";
import {Events} from "../../../plugins/events";

export default {
        components: {ImageCropper},
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                loading: true,
                updateFlags:{
                  ReceiptLogo:false,
                  invoiceLogo:false,
                  ReportLogo:false,
                  StartPageLogo:false,
                },
                logos: {
                    ReceiptLogo: null,
                    invoiceLogo: null,
                    ReportLogo: null,
                    StartPageLogo: null
                },
                loaders: {
                    ReceiptLogo: false,
                    invoiceLogo: false,
                    ReportLogo: false,
                    StartPageLogo: false
                },
                types: {
                    ReceiptLogo: 1,
                    invoiceLogo: 2,
                    ReportLogo: 3,
                    StartPageLogo: 4
                }
            }
        },
        methods: {
          updateImage(type){
            this.updateFlags[type]=true;
          },
          getData() {
            this.loading = true;

            this.axios.post(ENDPOINTS.SETTINGS.BASIC.GET, {}).then((res) => {
              if (res.status === 200) {
                this.logos.ReceiptLogo = res.data.formfillData.textFields.receiptLogo === 0 ? null : res.data.formfillData.textFields.receiptLogo;
                this.logos.invoiceLogo = res.data.formfillData.textFields.invoiceLogo === 0 ? null : res.data.formfillData.textFields.invoiceLogo;
                this.logos.ReportLogo = res.data.formfillData.textFields.reportLogo === 0 ? null : res.data.formfillData.textFields.reportLogo;
                this.logos.StartPageLogo = res.data.formfillData.textFields.startPageLogo === 0 ? null : res.data.formfillData.textFields.startPageLogo;

                this.loading = false;
              } else {
                Events.$emit("showSnackbar", {
                  message: self.$t('generic.lang_errorOccurred'),
                  color: "error"
                });

                this.loading = false;
              }
            }).catch(() => {
              Events.$emit("showSnackbar", {
                message: self.$t('generic.lang_errorOccurred'),
                color: "error"
              });

              this.loading = false;
            });
          },
            upload(logoType) {
                this.$refs[logoType].cropImage();
                this.loaders[logoType] = true;

                this.axios.post(ENDPOINTS.SETTINGS.BASIC.LOGOUPLOAD, {
                    logoUpload: this.logos[logoType],
                    typeOfLogo: this.types[logoType],
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });

                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loaders[logoType] = false;
                    this.updateFlags[logoType] = false;
                })
            }
        },
  mounted() {
            this.getData();
        },
    }
</script>

<style scoped>
    .dropdown-menu-header {
        z-index: 1 !important;
    }
</style>
