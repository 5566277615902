<template>
  <v-container fluid class="pa-0">

          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card pa-0 ma-0 shadow-none">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-title card-header-tab card-header">
              <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                {{ $t('settings.lang_geolocationSettings') }}
              </div>
              <div class="btn-actions-pane-right actions-icon-btn">
                <div class="btn-actions-pane-right actions-icon-btn">
                </div>
              </div>
            </div>
            <div class="card-body pa-0">
              <v-divider class="pa-0 ma-0"/>
              <div>
                <v-progress-linear
                    class="ma-0"
                    color="primary"
                    indeterminate
                    v-if="this.loading"
                ></v-progress-linear>
              </div>
              <v-card-text>




                <v-layout>
                  <v-flex md12 ml-2 mr-2>
                    {{$t('settings.chooseSystemLocation')}}
                    <v-select
                        :disabled="this.loading"
                        :items="countries"
                        :label="$t('generic.lang_country')"
                        dense
                        item-text="label"
                        item-value="value"
                        outlined
                        v-model="geoloc_systemcountry"
                    ></v-select>
                  </v-flex>
                </v-layout>

              </v-card-text>
              <v-divider class="pa-0 ma-0"/>


              <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-footer text-right pa-0">
                <v-spacer/>
                <v-btn
                    :disabled="this.loadUpdate || this.loading"
                    :loading="this.loadUpdate"
                    @click="updateData"
                    class="mr-5 btn btn-primary"
                    color="primary"
                >
                  {{ this.$t("generic.lang_save") }}
                </v-btn>
              </div>
            </div>

            <div id="onScreenKeyboardDiv">
              <vue-touch-keyboard
                  :accept="hideTouchKeyboard"
                  :cancel="hideTouchKeyboard"
                  :defaultKeySet="touchKeyboard.keySet"
                  :input="touchKeyboard.input"
                  :layout="touchKeyboard.layout"
                  :options="touchKeyboard.options"
                  class="internalWidth"
                  id="onScreenKeyboard"
                  v-if="touchKeyboard.visible"
              />
            </div>
          </div>

  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import {geol} from "../../../main";
import {handleCurrencyFormatting} from "../../../plugins/currencyFormater";
const countries = require('i18n-iso-countries');
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ar.json"));

export default {
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      tab: 0,
      geoloc_systemcountry: null,
      loadUpdate: false,
      loading: false,

      // itemCountries: [
      //   {name: this.$t("generic.lang_greatBritain"), id: "en"},
      //   {name: this.$t("generic.lang_germany"), id: "de"},
      //   {name: this.$t("generic.lang_france"), id: "fr"},
      //   {name: this.$t("generic.lang_swiss"), id: "de"},
      // ],
      // ----------------------------------
    };
  },
  computed: {
    countries () {
      const list = countries.getNames(this.$i18n.locale, { select: 'official' });
      return Object.keys(list).map((key) => ({ value: key.toLowerCase(), label: list[key] }));
    }
  },
  methods: {
    getData() {
      let self = this;
      this.loading = true;
      this.axios
          .post(ENDPOINTS.SETTINGS.GEOLOC.GET, {})
          .then((res) => {
            if (res.status === 200) {
              (this.geoloc_systemcountry = res.data.geoloc_systemcountry)
            } else {
              Events.$emit("showSnackbar", {
                message: self.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: self.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    updateData: function () {
      this.loadUpdate = true;
      this.axios
          .post(ENDPOINTS.SETTINGS.GEOLOC.UPDATE, {
            geoloc_systemcountry: this.geoloc_systemcountry,

          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_actionSuccessful"),
                color: "success",
              });
              geol.locale = this.geoloc_systemcountry;
              handleCurrencyFormatting(this.geoloc_systemcountry)
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch(() => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          })
          .finally(() => {
            this.loadUpdate = false;
          });
    },
  },
  mounted() {
    //GET DATA
    this.getData();
  },
};
</script>
