<template>
  <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card main-card">
    <div :class="[this.$vuetify.theme.dark? 'white--text' : 'white']" class="card-title card-header-tab card-header">
      <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
        {{$t('settings.lang_emailSettings')}}
      </div>
      <div class="btn-actions-pane-right actions-icon-btn">
        <div class="btn-actions-pane-right actions-icon-btn">
        </div>
      </div>
    </div>
    <v-divider class="pa-0 ma-0"/>
    <div>
      <v-progress-linear
          class="ma-0"
          color="primary"
          indeterminate
          v-if="this.loading"
      ></v-progress-linear>
    </div>
    <v-form ref="form" v-model="valide">
      <div class="card-body">
        <v-row>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSettingsOutServer')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="mailHost"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSetsPort')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              max="64000"
                              min="0"
                              outlined
                              required
                              type="number"
                              v-model="mailPort"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select :disabled="this.loading" :items="encryptions" :label="$t('settings.lang_mailSettingsEncryption')"
                          dense
                          item-text="name"
                          item-value="value"
                          outlined
                          v-model="encryption"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSettingsUsername')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="userName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSettingsPassword')"
                              :rules="[rules.required,rules.min]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              type="password"
                              v-model="mailPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSetsSenderName')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="senderName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading" :label="$t('settings.lang_mailSetsEmail')"
                              :rules="[rules.email,rules.required]"
                              @focus="showTouchKeyboard"

                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="senderMail"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-divider class="ma-0 pa-0"/>
      <div class="card-body">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12">
                <label class="text-muted">{{ this.$t('settings.lang_OptionalEmailFooterText') }}</label>
                <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :options="quillOptions"
                              @focus="showTouchKeyboard"
                              output="html" class="mb-4" v-model="emailFooter">

                </quill-editor>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-divider class="ma-0 pa-0"/>
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-footer text">
        <v-row justify="end" no-gutters>
          <v-btn :disabled="this.loading||!valide" @click="mailServerTest()" color="info" large>
            {{$t('settings.lang_mailTest')}}
          </v-btn>
          <v-btn :disabled="this.loading||!valide" @click="save()" color="success" large>
            {{$t('generic.lang_apply')}}
          </v-btn>
        </v-row>
      </div>
    </v-form>
    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';


export default {
  name: "EmailSettingsComponent",
  components: {quillEditor},
  mixins: [mixin],
  data() {
    return {
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ],
        },
      },
      valide: false,
      loading: true,
      rules: {
        email: (email) => {
          let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email) || this.$t("generic.lang_inputShouldBeValideEmail");
        },
        required: (field) => {
          return ((field && field !== '' && field.toString().length > 0) || this.$t("generic.lang_requiredField"))
        },
        min: (field) => {
          return ((field && field.toString().length >= 6) || this.$t("generic.lang_passwordShouldBeAtLeast6CharsMinimum"))
        }
      },
      encryptions: [
        {name: this.$t('generic.lang_gen_none'), value: 0},
        {name: 'SSL', value: 1},
        {name: 'TLS', value: 2},
      ],
      emailFooter: "",
      mailHost: null,
      mailPort: null,
      encryption: 0,
      userName: null,
      mailPassword: null,
      senderMail: null,
      senderName: null,
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.EMAIL.GET)
          .then(res => {
            if (res.data.status === "SUCCESS") {
              this.encryption = parseInt(res.data.mailEncryption)
              this.mailHost = res.data.mailHost
              this.mailPort = parseInt(res.data.mailPort)
              this.senderName = res.data.mailSenderName
              this.senderMail = res.data.mailSendereMailAddress
              this.userName = res.data.mailUsername
              this.emailFooter = res.data.emailFooter
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
            this.loading = false;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false;
      })
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.EMAIL.UPDATE, {
        mailEncryption: this.encryption,
        mailHost: this.mailHost,
        mailPort: this.mailPort,
        mailSenderName: this.senderName,
        mailSendereMailAddress: this.senderMail,
        mailUsername: this.userName,
        mailPassword: this.mailPassword,
        emailFooter: this.emailFooter,
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success",
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false;
      })
    },
    mailServerTest() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.EMAIL.TEST, {
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success",
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false;
      })
    }

  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>