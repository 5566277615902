<template>
  <div>
    <v-dialog max-width="700px" scrollable v-model="$parent.dialog" persistent>
      <v-card :loading="loading">
        <v-toolbar dense flat>
          <v-toolbar-title>
            <span v-if="mode==='create'">{{ this.$t('generic.lang_create') }} </span>
            <span v-else>{{ this.$t('generic.lang_edit') }} </span>
            {{ this.$t('accounting.lang_additionalPaymentMethodsH4') }}
          </v-toolbar-title>
          <v-spacer/>
          <v-btn @click="closeDialog" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="pa-0 ma-0"/>
        <v-card-text style="height: 400px;">
          <v-form v-model="valid" lazy-validation ref="form">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="paymentName"
                                :label="this.$t('settings.lang_paymentName')"
                                autocomplete="off"
                                required
                                :rules="[rules.required]"
                  />
                </v-col>
                <v-col class="pb-0" cols="12">
                  <v-textarea outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="freeText"
                              :label="this.$t('settings.lang_paymentText')"
                              autocomplete="off"
                  />
                </v-col>
                <v-col class="pt-0 mt-0" cols="12">
                  <v-checkbox class="mt-0" hide-details :label="this.$t('tablebee.lang_service_online')"
                              v-model="is_online"/>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider/>

        <v-card-actions>
          <v-row no-gutters v-if="mode==='create'" justify="end">
            <v-btn depressed large color="info" :disabled="!valid" :loading="loading" @click="create()">
              {{ this.$t('generic.lang_create') }}
            </v-btn>
          </v-row>
          <v-row no-gutters v-else justify="end">
            <v-btn large color="error" :disabled="!valid" :loading="loading" @click="deleteData()">
              {{ this.$t('generic.lang_delete') }}
            </v-btn>
            <v-btn large color="success" :disabled="!valid" :loading="loading" @click="update()">
              {{ this.$t('generic.lang_edit') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import mixin from '../../../../mixins/KeyboardMixIns';
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "../../../../config";


export default {
  name: "CreatePaymentComponent",
  mixins: [mixin],
  props: {
    mode: String,
    id: Number,
  },
  data() {
    return {
      loading: false,
      valid: false,
      paymentName: null,
      freeText: null,
      is_online: false,
      rules: {
        required(v) {
          return !!v || this.$t('generic.lang_requiredField')
        },
      }
    }
  },
  watch: {
    mode() {
      if (this.mode === 'edit') {
        this.getData()
      }
    }
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset();
      this.$parent.dialog = false;
    },
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.ADDITIONALPAYMENTS.GET, {
        additionalPaymentID: this.id,
      }).then(res => {
        this.$refs.form.reset()
        this.loading = false;
        this.paymentName = res.data.formfillData.textFields.paymentName
        this.freeText = res.data.formfillData.textFields.paymentText;
        this.is_online = res.data.formfillData.textFields.is_online
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
        this.loading = false;
      });
    },
    update() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.ADDITIONALPAYMENTS.UPDATE, {
        additionalPaymentID: this.id,
        paymentName: this.paymentName,
        paymentText: this.freeText,
        is_online: this.is_online
      }).then(res => {
        Events.$emit("showSnackbar", {
          color: "success",
          message: this.$t('settings.lang_paymentAddedSuccessfully')
        });
        this.$parent.dialog = false;
        this.$parent.getDataFromApi();
        this.$parent.resetSelectedRows();
        this.$refs.form.reset()
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
        this.loading = false;
      });

    },
    create() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.ADDITIONALPAYMENTS.CREATE, {
        paymentName: this.paymentName,
        paymentText: this.freeText,
        is_online: this.is_online
      }).then(res => {
        Events.$emit("showSnackbar", {
          color: "success",
          message: this.$t('settings.lang_paymentAddedSuccessfully')
        });
        this.$refs.form.reset()
        this.$parent.getDataFromApi();
        this.$parent.resetSelectedRows();
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
        this.loading = false;
      });
    },
    deleteData() {
      this.$parent.deleteData([this.id])
    }
  },
  mounted() {
    if (this.mode === 'edit') {
      this.getData();
    }
  }
}
</script>

<style scoped>

</style>
