<template>
  <v-container fluid class="pa-5 ma-2">
    <div class="main-card card pa-0 ma-0">
      <div>
        <b-tabs :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" content-class="mt-3">
          <b-tab active :title="$t('generic.lang_globalCashierOptions') ">
            <v-card elevation="0" :disabled="loading">

              <v-col cols="12" class="pb-0">
                <v-text-field v-model="stornoPassword" dense outlined :label="$t('settings.lang_voidingPassword')"/>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-checkbox v-model="playSounds" :label="$t('settings.lang_settings_sounds')"></v-checkbox>
                <v-checkbox v-model="checkStockCount" :label="$t('generic.lang_checkSaleStockCount')"></v-checkbox>
                <v-checkbox v-model="disallowNegativeStock"
                            :label="$t('generic.lang_disallowNegativeStockCount')"></v-checkbox>
                <v-checkbox v-model="void2OriginalSellerAndCashier"
                            :label="$t('settings.lang_AssignStornoToSeller')"></v-checkbox>
                <v-checkbox v-if="this.$store.getters['permissions/checkModule'](27)"
                            v-model="barcodeWithVarPriceWeight"
                            :label="$t('erp.lang_enableBarcodeWeighPrices')"></v-checkbox>
                <v-checkbox v-model="disableTip"
                            :label="$t('settings.lang_disableRestAsTipInCashout')"></v-checkbox>


              </v-col>
              <v-col class="" cols="12" >
                <p>{{$t('settings.defaultPLUAction')}}</p>
                <v-btn-toggle active-class="success" v-model="defaultPLUValue" mandatory>
                  <v-btn value="QTY" class="font-weight-bold ma-0">
                    {{ $t('generic.lang_cashier_qty') }}
                  </v-btn>

                  <v-btn value="PLU" class="font-weight-bold ma-0">
                    {{ $t('generic.lang_PLUCashier') }}
                  </v-btn>

                  <v-btn value="PRICE" class="font-weight-bold ma-0">
                    {{ $t('generic.lang_cashier_price') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loadingSave" :disabled="loadingSave" color="success" @click="saveSettings">
                  {{ $t('generic.lang_save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </b-tab>
          <b-tab :title="$t('settings.lang_currencyAndRounding')">
            <cashier-currency-and-rounding-component/>
          </b-tab>

          <b-tab :title="$t('settings.lang_pickUpNumberSettings')">
            <v-row class="px-3">
              <v-col cols="12">
                <v-switch :value="true" v-model="pickUpForm.pickUp_Active"
                          :label="$t('settings.lang_activatePickUpNumbers')"/>
              </v-col>
              <v-col cols="12">
                <v-switch :value="true" v-model="pickUpForm.pickupQRCodeActive"
                          :label="$t('settings.lang_scanQRCodeForPickUp')"/>
              </v-col>
              <v-col v-if="this.pickUpForm.pickUp_Active" cols="12" class="pt-0">
                <v-select v-model="pickUpForm.pickUp_Type" :label="$t('settings.lang_pickUpNumberType')"
                          :items="pickUpTypes" item-text="name"
                          item-value="value" outlined dense/>
              </v-col>

              <v-col v-if="this.pickUpForm.pickUp_Type === 'Number'" cols="12" class="pt-0">
                <v-text-field v-model="pickUpForm.pickUp_StartingNumber"
                              :label="$t('settings.lang_startWithPickUpNumber')" outlined dense/>
              </v-col>
              <v-col cols="12" class="text-right">

                <v-spacer></v-spacer>
                <v-btn depressed :loading="loadingSave" :disabled="loadingSave" color="success"
                       @click="savePickUpSetting">
                  {{ $t('generic.lang_save') }}
                </v-btn>

              </v-col>
            </v-row>
          </b-tab>

          <!-- translation options -->
          <b-tab :title="$t('erp.lang_translation')">
            <translation-options-component/>
          </b-tab>


          <b-tab :title="capitalize($t('erp.lang_nav_faktura'))">
            <global-billing-option-component/>
          </b-tab>
        </b-tabs>


      </div>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import TranslationOptionsComponent from "@/components/settings/sysetmdata/cashier/options/TranslationOptionsComponent";
import GlobalBillingOptionComponent
  from "@/components/settings/sysetmdata/cashier/options/GlobalBillingOptionComponent";
import {capitalize} from "lodash/string";
import CashierCurrencyAndRoundingComponent
  from "@/components/settings/sysetmdata/cashier/options/CashierCurrencyAndRoundingComponent";

export default {
  name: "GlobalCashierOptions",
  components: {CashierCurrencyAndRoundingComponent, GlobalBillingOptionComponent, TranslationOptionsComponent},
  data() {
    return {
      //pick up
      pickUpTypes: [
        {
          value: "Name",
          name: this.$t('settings.lang_pickUpNames')
        },
        {
          value: "Number",
          name: this.$t('settings.lang_pickUpNumbers')
        }
      ],
      pickUpForm: {
        pickUp_Type: '',
        pickUp_Active: '',
        pickupQRCodeActive: '',
        pickUp_StartingNumber: ''
      },

      //end pick up
      loading: false,
      loadingSave: false,
      // v-model
      checkStockCount: false,
      multiCurrency: true,
      playSounds: false,
      stornoPassword: '',
      disallowNegativeStock: false,
      void2OriginalSellerAndCashier: false,
      barcodeWithVarPriceWeight: false,
      disableTip: false,
      factureGrossPrice: false,
      places: "",
      selectedCurrency: null,
      defaultPLUValue: 'QTY',// PLU/QTY/PRICE
      currency: [
        {Name: 'EUR', id: 1},
        {Name: 'DOL', id: 2},
        {Name: 'CENT', id: 3},

      ],
      selectedRounds: null,
      rounds: [
        {Name: this.$t('settings.lang_LineRounding'), id: 1},
        {Name: this.$t('settings.lang_roundTotal'), id: 2},
      ],
    }
  },
  watch: {
    'pickUpForm.pickUp_Type': function (val) {
      if (val !== 'Number') this.pickUpForm.pickUp_StartingNumber = ''
    },
    'pickUpForm.pickUp_Active': function (val) {
      if (!val) this.pickUpForm.pickUp_Type = ''
    }
  },
  mounted() {
    this.getSettings();
    this.getPickUpSetting();
  },

  methods: {
    capitalize,
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.GLOBALCASHIEROPTIONS.GET).then((res) => {
        if (res.data.success) {
          this.checkStockCount = res.data.checkStockCount;
          this.playSounds = res.data.playSounds;
          this.disallowNegativeStock = res.data.disallowNegativeStock;
          this.void2OriginalSellerAndCashier = res.data.void2OriginalSellerAndCashier;
          this.barcodeWithVarPriceWeight = res.data.barcodeWithVarPriceWeight;
          this.disableTip = res.data.disableTip;
          this.factureGrossPrice = res.data.factureGrossPrice;
          this.selectedRounds = res.data.selectedRoundMethod;
          this.places = res.data.roundingPlaces;
          this.stornoPassword = res.data.stornoPassword
          this.defaultPLUValue = res.data.defaultPLUValue?res.data.defaultPLUValue:'QTY';
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getPickUpSetting() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.PICKUPSETTINGS.GET).then((res) => {
        this.pickUpForm = Object.assign({}, res.data);
      }).catch(() => {
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      })
    },
    savePickUpSetting() {
      this.loadingSave = true;
      this.axios.post(ENDPOINTS.SETTINGS.PICKUPSETTINGS.UPDATE, this.pickUpForm).then((res) => {

        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSave = false;
      })
    },
    saveSettings() {
      this.loadingSave = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.GLOBALCASHIEROPTIONS.UPDATE, {
        checkStockCount: this.checkStockCount,
        playSounds: this.playSounds,
        disallowNegativeStock: this.disallowNegativeStock,
        void2OriginalSellerAndCashier: this.void2OriginalSellerAndCashier,
        barcodeWithVarPriceWeight: this.barcodeWithVarPriceWeight,
        disableTip: this.disableTip,
        factureGrossPrice: this.factureGrossPrice,
        selectedRoundMethod: this.selectedRounds,
        roundingPlaces: this.places,
        stornoPassword: this.stornoPassword,
        defaultPLUValue: this.defaultPLUValue,
      }).then((res) => {
        this.loadingSave = false;

        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSave = false;
      })
    }
  }
}
</script>
